import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

gsap.from(".top__mv-wrap", {
  scale: 0,
  duration: 2,
  ease: "ease.out",
});

gsap.fromTo(
  ".top__mv-img",
  {
    y: 60,
  },
  {
    y: 0,
    delay: 0.5,
    opacity: 1,
    duration: 2,
    ease: "ease.out",
  }
);

const contents = gsap.utils.toArray(".js-scroll");
contents.forEach((content) => {
  gsap.from(content, {
    opacity: 0,
    y: 40,
    duration: 1,
    scrollTrigger: {
      trigger: content,
      start: "top 70%",
      // markers: true,
    },
  });
});
