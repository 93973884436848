// dataHandler.js

export function renderTable(data, tableId) {
  const tableBody = document.querySelector(`#${tableId}`);

  data.forEach((item) => {
    //h3 heading
    const area_dom = document.createElement("div");
    area_dom.className = "areaBlock";

    const h3_dom = document.createElement("h3");
    if (!item.visible) {
      h3_dom.textContent = "";
    }
    area_dom.appendChild(h3_dom);

    const table_wrap_dom = document.createElement("div");
    table_wrap_dom.className = "tableBlock";

    //table heading
    const table_dom = document.createElement("table");
    const thead_dom = document.createElement("thead");
    const thead_tr_dom = document.createElement("tr");
    const thead_array = ["DAY", "OPEN / START", "AREA", "INFORMATION"];

    thead_dom.className = "pc";
    thead_array.forEach((key) => {
      const th_dom = document.createElement("th");
      th_dom.className = "th";
      th_dom.textContent = key;
      thead_tr_dom.appendChild(th_dom);
      // console.log(key);
    });

    thead_dom.appendChild(thead_tr_dom);
    table_dom.appendChild(thead_dom);

    const tbodydom = document.createElement("tbody");
    table_dom.appendChild(tbodydom);

    table_wrap_dom.appendChild(table_dom);
    area_dom.appendChild(table_wrap_dom);

    item.schedules.forEach((key) => {
      const tbody_tr_dom = document.createElement("tr");

      // console.dir(key.date);

      const cell01 = document.createElement("td");
      cell01.textContent = key.day;
      cell01.className = "td_day";
      tbody_tr_dom.appendChild(cell01);

      const cell02 = document.createElement("td");
      if (key.date == "") {
        key.date = "ー";
      }
      cell02.innerHTML = key.time;
      cell02.className = "td_time";
      tbody_tr_dom.appendChild(cell02);

      const cell03 = document.createElement("td");
      if (key.area == "") {
        key.area = "ー";
      }
      cell03.textContent = key.area;
      cell03.className = "td_area";
      cell03.innerHTML =
        key.area +
        "<br>" +
        (key.soldout ? "<span>SOLD OUT</span>" : "") +
        (key.finished ? "<span>開催終了</span>" : "");

      // console.log(cell03);
      tbody_tr_dom.appendChild(cell03);

      const cell04 = document.createElement("td");
      cell04.className = "td_info";

      let linkitem = "";
      linkitem =
        key.info +
        '<br><a href="' +
        key.url +
        '" target="_blank">' +
        key.url +
        "</a>";
      if (linkitem == "") {
        linkitem = "ー";
      }
      cell04.innerHTML = linkitem;

      tbody_tr_dom.appendChild(cell04);

      tbodydom.appendChild(tbody_tr_dom);
    });

    tableBody.appendChild(area_dom);
  });
}
