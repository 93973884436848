// css
import "../scss/main.scss";
import "../scss/base/_variables.scss";
import "../scss/base/_mixin.scss";

// js
import "./main";
import "./gsap";

import remodal from "remodal";
