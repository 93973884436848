// main.js
import { fetchData, renderTable } from "./dataHandler.js";
import $ from "jquery";

document.addEventListener("DOMContentLoaded", function () {
  const jsonData = require("../data/schedule.json");
  const tableId = "dataTable";

  renderTable(jsonData, tableId);
});

// header modal
$(".hamburger").on("click", function () {
  $(this).toggleClass("is-active");
  if ($.remodal) {
    var modal =
      $.remodal.lookup[$("[data-remodal-id=header-modal]").data("remodal")];
    modal.close();
  }
});

// smooth-scroll
$(".anc").on("click", function () {
  if ($.remodal) {
    $(".hamburger").removeClass("is-active");
    var modal =
      $.remodal.lookup[$("[data-remodal-id=header-modal]").data("remodal")];
    modal.close();
  }

  var speed = 1000;
  var href = $(this).attr("href");
  var target = $(href == "#" || href == "" ? "html" : href);
  var position = target.offset().top;
  $("body,html").animate({ scrollTop: position }, speed, "swing");
  return false;
});

$(".title").on("click", function () {
  var findElm = $(this).next(".box");
  $(findElm).slideToggle();

  if ($(this).hasClass("close")) {
    $(this).removeClass("close");
  } else {
    $(this).addClass("close");
  }
});

// accordion
$(".accordion__title").on("click", function () {
  $(this).next().slideToggle();
  if ($(this).hasClass("show")) {
    $(this).removeClass("show");
  } else {
    $(this).addClass("show");
  }
});
